//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            dialogChangePassword: false,
            roles: [],
            banner:{},
            banners: [],
            dataID: '',
            server_errors: {
                title: '', priority: '',image:''
            },
            news: {},

            image: "",
            imageUrl: "",
            offset: 15,
            pagination: {},
            per_page: 12,
            headers: [
                {
                    text: "ຈັດລຳດັບ", align: "center", sortable: false, value: "priority",
                },
                {
                    text: "Title", align: "start", sortable: false, value: "title",
                },
                {
                    text: "Description", align: "start", sortable: false, value: "description",
                },
                {text: "Image", value: "image"},
                {text: "Created", value: "created_at"},
                {text: "Actions", value: "actions", sortable: false},
            ],
            truck_users: [],
            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        fetchData() {
            this.TableLoading = true;
            this.$admin.get('banner',
                {
                    params: {
                        page: this.pagination.current_page,
                        per_page: this.per_page,
                    }
                }
                ).then(res => {
                    this.banners = res.data.data.data;
                    this.pagination = res.data.data;
                (this.pagination)
                    this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        Save() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },

        onFileChange(e) {
            let input = e.target;
            let file = e.target.files[0];
            this.image = input.files[0];
            this.imageUrl = URL.createObjectURL(file);
            this.server_errors.imageFile = '';
            let formData = new FormData();
            formData.append("imageFile", this.image);
            this.$axios.post('/upload-image', formData)
                .then(res => {
                    if (res.status == 200) {
                        this.image = res.data.fileName;
                    }
                }).catch(error => {
                this.image = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });
        },

        Submit() {
            const data ={
               title: this.banner.title,
               link: this.banner.link,
               description: this.banner.description,
               priority:this.banner.priority,
               image: this.image,
            };

            this.$admin.post('/banner', data)
                .then(res => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            this.loading = false;
                            this.banner = {},
                                this.Close();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });
        },

        updateNews(id) {
            this.$router.push({name: 'UpdateBanner', params: {id: id}});
        },

        createNews() {
            this.$router.push({name: 'CreateBanner'});
        },
        Close() {
            this.$router.go(-1);
        },
        deleteItem(id) {
            this.dataID = id;
            this.$store.commit("modalDelete_State", true);
        },
        deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('banner/' + this.dataID).then(res => {
                if (res.status == 200) {
                    setTimeout(() => {
                        this.fetchData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 300);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        },
        reset() {
            this.$refs.form.reset();
        },
    },
    watch:{
        'banner.priority':function (){
            this.server_errors.priority = '';
        },
        'image':function (){
            this.server_errors.image = '';
        }
    },
    created() {
        this.fetchData();
    },
}
